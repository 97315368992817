import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from 'axios'
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';





import {
  PayoutId,
  PaymentStatus,
  PaymentMethod,
  PayoutGenerateDate,
  Sales,
  PayoutAmount,
  TotalSubscriptionAmount
}
  from "./PayoutCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import PayoutsModal from "./PayoutsModal";
import SourceModal from "./SourceModal";
import {
  Button,
  Col,
  Row,
  Card,
  CardBody,
  CardFooter,
  Collapse,
  Container
} from "reactstrap";
import { Tooltip as ReactTooltip } from "react-tooltip";
import classnames from "classnames";
function Payouts() {

  //meta title
  document.title = "Payouts | OnePlay";

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [col1, setcol1] = useState(true);
  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState(null);
  const [payout,setPayout]=useState([]);
  const [sourceButtonText,setSourceButtonText]=useState([]);
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      orderId: (order && order.orderId) || '',
      billingName: (order && order.billingName) || '',
      orderdate: (order && order.orderdate) || '',
      total: (order && order.total) || '',
      paymentStatus: (order && order.paymentStatus) || 'Paid',
      badgeclass: (order && order.badgeclass) || 'success',
      paymentMethod: (order && order.paymentMethod) || 'Mastercard',
    },
    validationSchema: Yup.object({
      orderId: Yup.string()
        .matches(
          /[0-9\.\-\s+\/()]+/,
          "Please Enter Valid Order Id"
        ).required("Please Enter Your Order Id"),
      billingName: Yup.string().required("Please Enter Your Billing Name"),
      orderdate: Yup.string().required("Please Enter Your Order Date"),
      total: Yup.string().matches(
        /[0-9\.\-\s+\/()]+/,
        "Please Enter Valid Amount"
      ).required("Total Amount"),
      paymentStatus: Yup.string().required("Please Enter Your Payment Status"),
      badgeclass: Yup.string().required("Please Enter Your Badge Class"),
      paymentMethod: Yup.string().required("Please Enter Your Payment Method"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateOrder = {
          id: order ? order.id : 0,
          orderId: values.orderId,
          billingName: values.billingName,
          orderdate: values.orderdate,
          total: values.total,
          paymentStatus: values.paymentStatus,
          paymentMethod: values.paymentMethod,
          badgeclass: values.badgeclass,
        };
        // update order
        dispatch(onUpdateOrder(updateOrder));
        validation.resetForm();
      } else {
        const newOrder = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          orderId: values["orderId"],
          billingName: values["billingName"],
          orderdate: values["orderdate"],
          total: values["total"],
          paymentStatus: values["paymentStatus"],
          paymentMethod: values["paymentMethod"],
          badgeclass: values["badgeclass"],
        };
        // save new order
        dispatch(onAddNewOrder(newOrder));
        validation.resetForm();
      }
      toggle();
    },
  });

  const t_col1 = () => {
    setcol1(!col1);
  };
  const toggleViewModal = (row) => {
    setModal1(!modal1);
    setRow(row);
  }
  const toggleSourceModal=(row)=>{
    setModal2(!modal2)
    setId(row.uuid);
    setRow(row);
  }

  const dispatch = useDispatch();


  useEffect(() => {
    const fetchData = async() => {
      try {
        const user = JSON.parse(localStorage.getItem('authUser'));
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/payouts`, {
          oneplay_user_id: user.oneplay_user_id
        });
        setPayout(response.data); 
        response.data.map((payout,index)=>{
          console.log(payout)
        if(payout.source_of_referral.length!==0 && payout.status==='pending'){
          setSourceButtonText(prev => {
            const newState = [...prev]; 
            newState[index] = 'View Source'; 
            return newState; 
          });
          
        }
        else if(payout.status==='accepted' || payout.status==='completed'){
          setSourceButtonText(prev => {
            const newState = [...prev]; 
            newState[index] = 'View Source'; 
            return newState; 
          });
        }
        else{
          setSourceButtonText(prev => {
            const newState = [...prev]; 
            newState[index] = 'Add Source'; 
            return newState; 
          });
        }
      })
      
      } catch (error) {
        console.error("Error fetching payout data:", error);
      }
    };
  
    fetchData(); 
  }, []);
  
  

  useEffect(()=>{
  console.log(sourceButtonText)
  },[sourceButtonText])

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const handleOrderClick = arg => {
    const order = arg;
    setOrder({
      id: order.id,
      orderId: order.orderId,
      billingName: order.billingName,
      orderdate: order.orderdate,
      total: order.total,
      paymentStatus: order.paymentStatus,
      paymentMethod: order.paymentMethod,
      badgeclass: order.badgeclass,
    });

    setIsEdit(true);

    toggle();
  };

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);
  const [id,setId]=useState(null);
  const [row,setRow]=useState(null);
  const onClickDelete = (order) => {
    setOrder(order);
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    if (order && order.id) {
      dispatch(onDeleteOrder(order.id));
      setDeleteModal(false);
    }
  };
  const handleOrderClicks = () => {
    setOrderList("");
    setIsEdit(false);
    toggle();
  };
  
  
  const columns = useMemo(
    () => [

      {
        Header: "ID",
        accessor: "id",
        width: '150px',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <PayoutId {...cellProps} />;
        }
      },
      {
        Header: 'Payout Generate Date ',
        accessor: 'payout_generate_date',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
            return <PayoutGenerateDate {...cellProps} />;
          }
      },
      {
        Header: 'Sales',
        accessor: 'subscriptions',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Sales {...cellProps} />;
        }
      },
      {
        Header: 'Total Subscription Amount',
        accessor: 'revenue',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <TotalSubscriptionAmount {...cellProps} />;
        }
      },
      {
        Header: 'Payout Amount(INR)',
        accessor: 'payout_amount',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return <PayoutAmount {...cellProps} />;
        }
      },
      {
        Header: 'Source of referral',
        accessor: 'source_referral',
        filterable: true,
        disableFilters: true,
        Cell: ({row}) => {
          return (
            <>
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={()=>toggleSourceModal(row.original)}
              data-tooltip-id="source"
              id={row.original.id}
            >
              {row.original.status==='accepted' || row.original.status==='completed' || (row.original.status==='pending' && row.original.source_of_referral.length>0)?'View Source':row.original.status==='rejected'?'Edit Source':'Add Source'}
            </Button>
            <ReactTooltip
        id="source"
        place="bottom"
        content="Please include your website, YouTube, or social network links for verification."
        style={{ backgroundColor: "#eed8ff", color: "#A83AFE" }}
      />
            </>
            );
        }
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableFilters: true,
        Cell: (cellProps) => {
          return <PaymentStatus {...cellProps} />;
        }
      },
      {
        Header: 'Payout release date',
        accessor: 'payout_release_date',
        disableFilters: true,
        Cell: (cellProps) => {
          return <PayoutGenerateDate {...cellProps} />;
        }
      },
      {
        Header: 'Remarks',
        accessor: 'remarks',
        disableFilters: true,
        Cell: (cellProps) => {
          return <PaymentMethod {...cellProps} />;
        }
      },
      {
        Header: 'Payment Screenshot',
        accessor: 'paymentScreenshot',
        disableFilters: true,
        Cell: ({row}) => {
          return (
            row.original.payout_disbursal_screenshot!==null && (<Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={()=>toggleViewModal(row.original)}
              id={row.original.id}
            >
              View Screenshot
            </Button>));
        }
      },
      
    ],
    []
  )
  
  return (
    <React.Fragment>
      <PayoutsModal isOpen={modal1} toggle={toggleViewModal} payout={row} />
      <SourceModal isOpen={modal2} toggle={toggleSourceModal} id={id} payout={row}/>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Payouts/Reports" breadcrumbItem="Payouts" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  {
                    payout && payout.length===0 ? (
                      <Container>
                        <Row>
                          <Col className="text-center">
                            <div className="fw-semibold">
                              No data available
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    )
                  :
                  sourceButtonText.length>0 && <TableContainer
                    columns={columns}
                    data={payout}
                    isGlobalFilter={true}
                    customPageSize={5}
                  />}
                </CardBody>
                <CardFooter>
                  <Row className="mt-3">
                  <Col lg={12}>
                    {/* <CardTitle className="h4">Terms And Conditions</CardTitle> */}
                    {/* <p className="card-title-desc">
                     <span className="text-primary">*</span>Terms And Conditions
                    </p> */}

                    <div className="accordion" id="accordion">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              { collapsed: !col1 }
                            )}
                            type="button"
                            onClick={t_col1}
                            style={{ cursor: "pointer" }}
                          >
                           <span style={{color:'red'}}>*</span> Terms And Conditions
                          </button>
                        </h2>

                        <Collapse isOpen={col1} className="accordion-collapse">
                          <div className="accordion-body">
                            <div className="text-muted">
                              <ul>
    <li>The first monthly subscription plan purchased by the user will be eligible for the payout. Hourly plans are excluded from payouts</li>
    <li>Every month on the 5th, a payout report will be generated for the previous month.</li>
    <li>Payment will be made within 30 days of the report being generated.</li>
    <li>Payment is subject to verification of the Source referral link.</li>
    <li>Source referral link verification results will be listed under status and will be available in 7-10 working days from the date of data submission.</li>
    <li>In the event of a verification failure, the cause will be provided in the remarks field.</li>
    <li>Failure to verify may result in payment delays.</li>
    <li>If you have any problems, please contact <a href="mailto:affiliate@oneplay.in" className="text-primary">affiliate@oneplay.in.</a></li>
</ul>

                            </div>
                          </div>
                        </Collapse>
                      </div>
                      </div>
                  </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}
Payouts.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default Payouts;