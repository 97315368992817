import React from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, FormFeedback,Form} from "reactstrap";

import withRouter from "components/Common/withRouter";


const TermsAndConditions = props => {

  //meta title
  document.title = "TermsAndConditions | OnePlay";

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        
      </div>
      <div className="account-pages my-5 pt-sm-5">
      <Container>
      <Row className="justify-content-center">
      <Col className="col-11">
      


            <div><h2>Terms of Use</h2></div>
            <div className="mb-4"><h5>Last Updated: 2 May 2024</h5></div>
            <div style={{fontSize:'14px'}}>
        <Row className="mb-1">

            <div><p><span className="text-primary fw-bold">1. Agreement</span> <div style={{marginLeft:'15px'}}>By choosing to participate in our Affiliate Program, you are indicating your acceptance and understanding of the terms and conditions outlined herein.</div></p></div>

        </Row>
        <Row className="mb-1">
            <div><p><span className="text-primary fw-bold">2. Eligibility</span> <div style={{marginLeft:'15px'}}>To be eligible for participation in the Affiliate Program, individuals must be aged 18 years or older.</div></p></div>

        </Row>
        <Row className="mb-1">
            <div><p><span className="text-primary fw-bold">3. Registration</span> <div style={{marginLeft:'15px'}}>Prospective affiliates must complete the registration process, which includes agreeing to abide by these terms and conditions.</div></p></div>

        </Row>
        <Row className="mb-1">
            <div><p><span className="text-primary fw-bold">4. Promotion</span> <div style={{marginLeft:'15px'}}>Affiliates are granted authorization to promote our products and services through approved marketing materials and methodologies.</div></p></div>

        </Row>
        <Row className="mb-1">
            <div><p><span className="text-primary fw-bold">5. Commissions</span> <div style={{marginLeft:'15px'}}>Affiliates stand to earn commissions on qualifying sales that are generated through the utilization of their unique referral codes.</div></p></div>

        </Row>
        <Row className="mb-1">
            <div><p><span className="text-primary fw-bold">6. Payment</span>  <div style={{marginLeft:'15px'}}>Every month on the 5th, a payout report will be generated for the previous month. Payment will be made within 30 days of the report being generated. Payment is subject to verification of the referral code. Referral code verification results will be listed under status and will be available in 7-10 working days from the date of data submission. In the event of a verification failure, the cause will be provided in the remarks field. Failure to verify may result in payment delays. Only the first monthly subscription purchased after successful signup using the referral code will be considered for payment. Subsequent subscriptions bought thereafter will not be eligible for payment.</div></p></div>

        </Row>
        <Row className="mb-1">
            <div><p><span className="text-primary fw-bold">7. Prohibited Activities</span>  <div style={{marginLeft:'15px'}}>Affiliates are strictly prohibited from engaging in any unethical or illegal activities while promoting our products and services.</div></p></div>

        </Row>
        <Row className="mb-1">
            <div><p><span className="text-primary fw-bold">8. Termination</span> <div style={{marginLeft:'15px'}}>We reserve the right to terminate an affiliate's participation in the program if they are found to be in violation of any of the terms outlined in this agreement.</div></p></div>

        </Row>
        <Row className="mb-1">
            <div><p><span className="text-primary fw-bold">9. Modification</span> <div style={{marginLeft:'15px'}}>These terms and conditions are subject to change at any time. Affiliates are responsible for staying informed about any updates or modifications to the terms.</div></p></div>

        </Row>
        <Row className="mb-1">
            <div><p><span className="text-primary fw-bold">10. Governing Law</span> <div style={{marginLeft:'15px'}}>Any disputes arising from the interpretation or implementation of these terms and conditions will be governed by the laws of [Jurisdiction].</div></p></div>

        </Row>
        <Row className="mb-1">
            <div><p><span className="text-primary fw-bold">11. Contact</span> <div style={{marginLeft:'15px'}}>For any inquiries or concerns regarding the Affiliate Program, please reach out to us at <a href="mailto:affiliate@oneplay.in" className="text-primary">affiliate@oneplay.in.</a></div></p></div>

        </Row>
      
        <p className="mt-3">By participating in our Affiliate Program, you acknowledge that you have read, understood, and agreed to these terms and conditions.</p>
        <p className="mt-4 text-secondary">
        © {new Date().getFullYear()} RainBox Media Pvt Ltd. All Rights Reserved.
                </p>
                </div>
        </Col>
        </Row>
        </Container>
        
      </div>
    </React.Fragment>
  );
};

export default withRouter(TermsAndConditions);

