import React, { useEffect,useState } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, FormFeedback,Form} from "reactstrap";
import PropTypes from "prop-types";
// Formik Validation
import * as Yup from "yup";
import { useFormik} from "formik";
import withRouter from "components/Common/withRouter";

// action
import { registerUser, apiError } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

// import images
import profileImg from "../../assets/images/profile-image.png";
import logoImg from "../../assets/images/logo.png";
const Register = props => {

  //meta title
  document.title = "Register | OnePlay - React Admin & Dashboard Template";

  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&*+,-./:;<=>?@\^_|~])[A-Za-z\d!#$%&*+,-./:;<=>?@\^_|~]{8,}$/;
  const emailRegex= /^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-_]+\.[a-zA-Z]{2,}$/;
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      calling_code:'',
      mobile: '',
      password: '',
      confirmPassword:'',
      termsAndConditions:false,
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
      .email("Must be a valid Email")
      .max(255).required("Please Enter Your Email").matches(
        emailRegex,
        "Not a valid email address"
      ),
      calling_code:Yup.string().required("Required").matches(
        /\+\d{1,3}(-\d{1,4})?/,
        "Invalid"
      ),
      mobile: Yup.string().required("Please Enter Your Phone Number").matches(
        /^\d+$/,
        'Mobile number should contain only digits'
      ).test('len', 'Mobile number must be exactly 10 digits', val => {
        // const mobileStr = val?.toString(); 
        return (val ?? '').length === 10;
      }),
      password: Yup.string().required("Please Enter Your Password").matches(
        passwordRegex,
        "Password should be at least 8 characters, 1 UPPERCASE, 1 lowercase, 1 Symbol and 1 number!"
      ),
      confirmPassword: Yup.string().when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password does not match"
        ),
    }),
    termsAndConditions: Yup.boolean()
    .required("You must accept the terms and conditions")
    .oneOf([true], "You must accept the terms and conditions"),
  }),
    onSubmit: (values) => {
      const { termsAndConditions, ...rest } = values;
      dispatch(registerUser(rest,props.router.navigate));
    }
  });

  const { user, registrationError, loading,success_msg,error_msg } = useSelector(state => ({
    user: state.Account.user,
    registrationError: state.Account.registrationError,
    loading: state.Account.loading,
    success_msg:state.Account.success_msg,
    error_msg:state.Account.error_msg
  }));

  useEffect(() => {
    dispatch(apiError(""));
  }, []);
  
  const handleShowPassword=()=>{
    setShowPassword((prev)=>!prev);
  }
  const handleShowConfirmPassword=()=>{
    setShowConfirmPassword((prev)=>!prev);
  }
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Register</h5>
                        <p>Start your OnePlay partner journey now.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" style={{height: '100px' }} />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {/* {success_msg && user && user ? (
                        <Alert color="success">
                          Register User Successfully
                        </Alert>
                      ) : null}  */}

                       {error_msg && registrationError && registrationError ? (
                        <Alert color="danger">{registrationError}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <Row className="mb-3">
                        <Label className="form-label">Phone Number</Label>
                        <div className="col-3 col-md-3">
    <Input
        name="calling_code"
        id="calling_code"
        className="form-control"
        list="datalistOptions"
        placeholder="e.g. +91"
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values.calling_code}
        invalid={validation.touched.calling_code && validation.errors.calling_code ? true : false}
    />
    <datalist id="datalistOptions">
    <option value="+93" />
<option value="+355" />
<option value="+213" />
<option value="+1-684" />
<option value="+376" />
<option value="+244" />
<option value="+1-264" />
<option value="+672" />
<option value="+1-268" />
<option value="+54" />
<option value="+374" />
<option value="+297" />
<option value="+43" />
<option value="+994" />
<option value="+1-242" />
<option value="+973" />
<option value="+880" />
<option value="+1-246" />
<option value="+375" />
<option value="+32" />
<option value="+501" />
<option value="+229" />
<option value="+1-441" />
<option value="+975" />
<option value="+591" />
<option value="+387" />
<option value="+267" />
<option value="+55" />
<option value="+246" />
<option value="+1-284" />
<option value="+673" />
<option value="+359" />
<option value="+226" />
<option value="+257" />
<option value="+855" />
<option value="+237" />
<option value="+1" />
<option value="+238" />
<option value="+1-345" />
<option value="+236" />
<option value="+235" />
<option value="+56" />
<option value="+86" />
<option value="+61" />
<option value="+57" />
<option value="+269" />
<option value="+682" />
<option value="+506" />
<option value="+385" />
<option value="+53" />
<option value="+599" />
<option value="+357" />
<option value="+420" />
<option value="+243" />
<option value="+45" />
<option value="+253" />
<option value="+1-767" />
<option value="+1-809" />
<option value="+670" />
<option value="+593" />
<option value="+20" />
<option value="+503" />
<option value="+240" />
<option value="+291" />
<option value="+372" />
<option value="+251" />
<option value="+500" />
<option value="+298" />
<option value="+679" />
<option value="+358" />
<option value="+33" />
<option value="+689" />
<option value="+241" />
<option value="+220" />
<option value="+995" />
<option value="+49" />
<option value="+233" />
<option value="+350" />
<option value="+30" />
<option value="+299" />
<option value="+1-473" />
<option value="+1-671" />
<option value="+502" />
<option value="+44-1481" />
<option value="+224" />
<option value="+245" />
<option value="+592" />
<option value="+509" />
<option value="+504" />
<option value="+852" />
<option value="+36" />
<option value="+354" />
<option value="+91" />
<option value="+62" />
<option value="+98" />
<option value="+964" />
<option value="+353" />
<option value="+44-1624" />
<option value="+972" />
<option value="+39" />
<option value="+225" />
<option value="+1-876" />
<option value="+81" />
<option value="+44-1534" />
<option value="+962" />
<option value="+7" />
<option value="+254" />
<option value="+686" />
<option value="+383" />
<option value="+965" />
<option value="+996" />
<option value="+856" />
<option value="+371" />
<option value="+961" />
<option value="+266" />
<option value="+231" />
<option value="+218" />
<option value="+423" />
<option value="+370" />
<option value="+352" />
<option value="+853" />
<option value="+389" />
<option value="+261" />
<option value="+265" />
<option value="+60" />
<option value="+960" />
<option value="+223" />
<option value="+356" />
<option value="+692" />
<option value="+222" />
<option value="+230" />
<option value="+262" />
<option value="+52" />
<option value="+691" />
<option value="+373" />
<option value="+377" />
<option value="+976" />
<option value="+382" />
<option value="+1-664" />
<option value="+212" />
<option value="+258" />
<option value="+95" />
<option value="+264" />
<option value="+674" />
<option value="+977" />
<option value="+31" />
<option value="+687" />
<option value="+64" />
<option value="+505" />
<option value="+227" />
<option value="+234" />
<option value="+683" />
<option value="+850" />
<option value="+47" />
<option value="+968" />
<option value="+92" />
<option value="+680" />
<option value="+970" />
<option value="+507" />
<option value="+675" />
<option value="+595" />
<option value="+51" />
<option value="+63" />
<option value="+48" />
<option value="+351" />
<option value="+1-787" />
<option value="+974" />
<option value="+242" />
<option value="+40" />
<option value="+250" />
<option value="+590" />
<option value="+290" />
<option value="+1-869" />
<option value="+1-758" />
<option value="+508" />
<option value="+1-784" />
<option value="+685" />
<option value="+378" />
<option value="+239" />
<option value="+966" />
<option value="+221" />
<option value="+381" />
<option value="+248" />
<option value="+232" />
<option value="+65" />
<option value="+1-721" />
<option value="+421" />
<option value="+386" />
<option value="+677" />
<option value="+252" />
<option value="+27" />
<option value="+82" />
<option value="+211" />
<option value="+34" />
<option value="+94" />
<option value="+249" />
<option value="+597" />
<option value="+268" />
<option value="+46" />
<option value="+41" />
<option value="+963" />
<option value="+886" />
<option value="+992" />
<option value="+255" />
<option value="+66" />
<option value="+228" />
<option value="+690" />
<option value="+676" />
<option value="+1-868" />
<option value="+216" />
<option value="+90" />
<option value="+993" />
<option value="+1-649" />
<option value="+688" />
<option value="+256" />
<option value="+380" />
<option value="+971" />
<option value="+598" />
<option value="+998" />
<option value="+678" />
<option value="+379" />
<option value="+58" />
<option value="+84" />
<option value="+681" />
<option value="+967" />
<option value="+260" />
<option value="+263" />

</datalist>
{validation.touched.calling_code && validation.errors.calling_code ? (
                          <FormFeedback type="invalid">{validation.errors.calling_code}</FormFeedback>
                        ) : null}
</div>

                        <div className="col-9 col-md-9">
                        <Input
                          name="mobile"
                          type="text"
                          placeholder="Enter Phone Number"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.mobile || ""}
                          invalid={
                            validation.touched.mobile && validation.errors.mobile ? true : false
                          }
                        />
                        {validation.touched.mobile && validation.errors.mobile ? (
                          <FormFeedback type="invalid">{validation.errors.mobile}</FormFeedback>
                        ) : null}
                        </div>
                        <span className="text-primary" style={{fontSize:'80%'}}>Enter the phone number associated with your Oneplay account.</span>
                       
                      </Row>
                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <div className="input-group">
                          <Input
                            name="password"
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={validation.touched.password && validation.errors.password ? true : false}
                          />
                          <button type="button" onClick={handleShowPassword} className="input-group-text"><i className={`bx ${showPassword ? 'bx-hide' : 'bx-show-alt'}`}></i></button>
                          {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                         {!validation.touched.password || !validation.errors.password ? (
    <span className="text-primary" style={{fontSize:'80%'}}>Password should be at least 8 characters, 1 UPPERCASE, 1 lowercase, 1 Symbol and 1 number!</span>
  ) : null}
                        </div>
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Confirm Password</Label>
                        <div className="input-group">
                          <Input
                            name="confirmPassword"
                            type={showConfirmPassword ? "text" : "password"}
                            placeholder="Enter Confirm Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.confirmPassword || ""}
                            invalid={validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false}
                          />
                          <button type="button" onClick={handleShowConfirmPassword} className="input-group-text"><i className={`bx ${showConfirmPassword ? 'bx-hide' : 'bx-show-alt'}`}></i></button>
                          {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                          <FormFeedback type="invalid">{validation.errors.confirmPassword}</FormFeedback>
                        ) : null}
                        </div>
                        
                      </div>

                      
                     
                      <div className="mt-4 text-center mb-0">
                        <div className="form-check">
                        <Input
                          name="termsAndConditions"
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                          checked={validation.values.termsAndConditions}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.termsAndConditions}
                          invalid={
                            validation.touched.termsAndConditions &&
                            validation.errors.termsAndConditions
                              ? true
                              : false
                          }
                        />
                        <Label
                          className="form-check-label"
                          for="customControlInline"
                        >
                         

                          By registering you agree to the OnePlay{" "}
                          <Link to="/tnc" target="_blank" rel="noopener noreferrer" className="text-primary">
                            Terms of Use
                          </Link>
                        </Label>
                        {validation.touched.termsAndConditions && validation.errors.termsAndConditions ? (
            <FormFeedback type="invalid">{validation.errors.termsAndConditions}</FormFeedback>
          ) : null}
                      </div>
                       
                      </div>
                      <div className="mt-4">
                        <button
                          className="btn btn-primary btn-block "
                          type="submit"
                        >
                          Register
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                <p>
                © {new Date().getFullYear()} RainBox Media Pvt Ltd. All Rights Reserved.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Register);

Register.propTypes = {
  history: PropTypes.object,
};